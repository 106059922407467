$wht : #fafafa;
$blk: #000;
$primary: #f5546a;
$secondary: #8e064a;

/* Button variation */

.btn.btn-primary{
	color: $wht;
	border-radius: 0px;
	border: 3px dashed $secondary;
	padding: 25px 60px;
	background: $primary;
	font-size: 1.5em;
	font-weight: bold;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;

	@media (max-width: 420px) {
		font-size: 1.25em;
		padding: 25px;
	}
}

.btn.btn-primary:hover{
	color: #fff;
	background: $secondary;
}
.btn.btn-primary.solid:hover{
	background: none;
}



ul.navbar-nav > li > a{
	padding: 10px 18px;
	font-weight: 600;
	color: #fff !important;
	margin:0;
	background: $primary;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	// border: 2px dashed $secondary;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
}

ul.navbar-nav > li:hover > a,
ul.navbar-nav > li.active > a{
	color: $secondary !important;
	border: 2px dashed $primary;
	background: transparent;
}


a {
	color: #564B3D;
	text-shadow: none;
}

/** LOGIN FORM **/


.modal-dialog
{
	max-width: 300px;
	text-align: center;
	margin: 8em auto;
}

.modal-header, .modal-footer
{
	background: $primary;
	color: #fff;
}

input#username
{
	margin-bottom: 20px;
}


/** END LOGIN FORM **/


.row {
	margin-left: 0px;
	margin-right: 0px;
}

nav
{
	z-index: 1001;
}

.navbar-brand {
    float: none;
    height: 50px;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
    img {
    	max-width: 310px;
    	@media (max-width: 388px) {
    		max-width: 200px;
    	}
    }
}

.navbar-nav>li>a {

	@media (min-width: 768px) {
		padding-top: 45px;
	}
	text-align: center;
    padding-bottom: 15px;
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 10px 10px;
    margin-top: 0px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    @media(max-width: 767px) {
		margin-top: 0.5em;
    }
	@media (max-width: 388px) {
		margin-top: 14px;
	}    
}

@media (min-width: 768px) {
	.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
	    margin-left: 0px;
	}
}

.modal-content {
	color: $secondary;
	padding: 0px;
	background: $wht;

	h2 {
		text-align:center;
	}

	input#submit {
		background: $primary;
		padding: 1em 2em;
		color: $wht; 
		border: none;
	}
}



.banner {
	margin-top: 144px;
	img {
		border-radius: 5px;
	}
}




.panel-top {
	margin-left: 5%;
    top: 175px;
    position: absolute;
    // margin-right: auto;
    height: auto;
    @media (min-width: 991px) {
    	width: 400px;
    }
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    background-color: #B19F87;
    h1 {
    	text-align: center;
    }
    @media (max-width: 991px) {
    	display:none;
    }    	
}

.panel-top-mobile {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	background-color: #B19F87;
    width: 100%;
    max-width: 400px;	
    border-radius: 5px;
    h1 {
    	padding: 10px;
    	text-align: center;
    }    
}


#signUpForm label {
    vertical-align: top;
    margin: 0 !important;
    padding: 0 !important;
    height: 30px !important;
    line-height: 30px !important;
    float: none !important;
    text-align: right !important;
    font-family: "Open Sans",Calibri,sans-serif !important;
    font-size: 14px !important;
    display: inline-block !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    width: 22% relative !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    color: #F8F8F8 !important;
}

.sec-panel {
	margin-top: 25px;
	background-color: white;
	border-radius: 5px;
	color: #564B3D;
	padding: 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.sec-panel-m {
	@media (min-width: 992px) {
		display: none;
	}	
	margin-top: 25px;
	background-color: white;
	border-radius: 5px;
	color: #564B3D;
	padding: 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.browse {
	margin-top:25px;
	margin-bottom: 25px;
	text-align: center;
	&-button {
		display: block;
		width: 33%;
		margin-left:auto;
		margin-right: auto;
		border: 1px solid black;
		border-radius: 5px;
		padding: 12px;
		font-size:16px;
		font-weight: 700px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: #564B3D;
		}	
	}
}

.join {
	margin-top:25px;
	margin-bottom: 25px;
	text-align: center;
	&-button {
		display: block;
		width: 33%;
		margin-left:auto;
		margin-right: auto;
		border: 1px solid black;
		border-radius: 5px;
		padding: 12px;
		font-size:16px;
		font-weight: 700px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: #564B3D;
		}	
	}
}


.top-pad {
	background-color: #F8F8F8;
	color: #564B3D; 
	margin-top: 137px;
	border-radius: 5px;
	padding:15px;
}

footer {
	margin-top: 10px;
	color: #564B3D; 
	background: white;
	padding-top: 10px;
}


.noPad {
	padding: 0;
}

.whtTxt {
	color: $wht;
	text-shadow: 2px 2px 2px $blk;
}

.blkTxt {
	color: $blk;
}

@media (max-width: 767px) {
  .navbar-toggle {
    margin-top: 0.5em;
  }

  .navbar-right {
  	margin-top: 0px;
  }
}